<template>
    <div class="service-item">
        <div class="left">{{data.REMARK}}</div>
        <div class="right">
            <div class="item item1">
                <span class="txt">抓取地址：</span>
                <el-input class="serviceconfig" v-model="data.GRABMAINURL" placeholder="请输入内容"></el-input>
                <a :href="data.GRABMAINURL" target="_blank"><img :src="require('@image/dataService_management/service_manage/访问.png')" class="img"></a>
            </div>
            <div class="item item2">
                <span class="txt content">发布周期：{{ fbzq }}</span>
                <div class="content">
                    <span class="txt">抓取周期：</span>
                    <el-input-number class="servconfinputnum" size="mini" v-model="data.GRABCYCLE"></el-input-number>
                    <el-select class="servconfsel" v-model="data.GRABCYCLEUNIT" placeholder="请选择">
                        <el-option
                            v-for="item in zqdwList"
                            :key="item.CODE"
                            :label="item.NAME"
                            :value="item.CODE">
                        </el-option>
                    </el-select>
                </div>
                <div class="content">
                    <span class="txt">负责人：</span>
                    <el-select class="serviceconfig" v-model="data.MANAGERID" placeholder="请选择">
                        <el-option v-for="(item,index) in userInfoList" :key="`${index}`" :value="item.ID" :label="item.REALNAME"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="item item3">
                <el-button type="primary" size="small" class="commonGridButton" @click="saveData">保 存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    props:{
        data:{
            default:()=>({
                ID: "",
                REMARK:'',
                GRABMAINURL: "",
                GRABCYCLE: 0,
                GRABCYCLEUNIT: "",
                ZQ: "",
                ZQDW: "",
                MANAGERID: ""
            })
        },
        zqdwList:{
            type:Array,
            default:()=>[]
        },
        userInfoList:{
            type:Array,
            default:()=>[]
        },
    },
    computed:{
        fbzq(){
            let dw = this.data.ZQDW;
            dw = this.zqdwList.find(ele=>ele.CODE === dw);
            let dwStr = dw&&dw.NAME ||'';
            return this.data.ZQ + dwStr
        },
    },
    mounted(){
        // this.check();
    },
    methods:{
        ...mapActions([
            'saveServiceAdministration',
            'GetZqdwList',
            'getUserInfoList',
        ]),
        async saveData(){
            const payload = {
                ...this.data
            };
            for (const key in payload) {
                payload[key] = payload[key]||"";
            }
            const res = await this.saveServiceAdministration(payload);
            console.log('serviceAdministration====res--->',res);
            this.$alert(res);
        },
        async check(){
            if(this.zqdwList && !(this.zqdwList.length>0)){
                await this.getZqdwList();
            }
            if(this.userInfoList && !(this.userInfoList.length>0)){
                await this.getUserList();
            }

        },
        async getZqdwList() {
            this.zqdwList = [];
            let result = await this.GetZqdwList();
            if (result && result.length > 0) {
                this.zqdwList = result;
            }
        },
        async getUserList() {
            this.userInfoList = [];
            let result = await this.getUserInfoList();
            if (result && result.length > 0) {
                this.userInfoList = result;
            }
        },
    },

}
</script>

<style lang="scss" scoped>
.service-item{
    height: 200px;
    width: 100%;
    display: flex;
    .left{
        flex: 0 0 50px;
        background-color: rgba(36, 92, 170, 1);
        writing-mode: vertical-lr;
        font-size: 20px;
        color: #FFF;
        letter-spacing: 10px;
        text-align: center;
        line-height: 50px;
    }
    .right{
        flex: 1 1 0;
        background-color:#f2f2f2;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding-right: 10px;
        padding-left: 30px;
        .item{
            flex: 1 1 0;
            display: flex;
            align-items: center;
            border-bottom:1px solid #d5d5d5;
            .txt{
                white-space: nowrap;
                // flex:0 0 100px;
                text-align: left;
            }
        }
        .item1{
            .serviceconfig{
                display: flex;
                align-items: center;
                margin:0 15px
            }
            .a{
                margin-right: 15px;
                img{
                    vertical-align: middle;
                }
            }
        }
        .item2{
            .content{
                display: flex;
                align-items: center;
                margin-right: 50px;
                .servconfinputnum,.servconfsel,.serviceconfig{
                    margin-left: 10px;
                }
            }
        }
    }
}
</style>