<template>
    <div class="service-manage" style="padding-top:20px;">
        <el-tabs type="border-card" @tab-click="handleClick">
            <el-tab-pane v-for="(item,index) in list" :key="`${index}`" :label="item.ZDMC">
                <service-item v-for="ele in item.DATA" :key="ele.ID" :data.sync="ele" :zqdwList.sync="zqdwList" :userInfoList.sync="userInfoList"/>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import ServiceItem from "./service_item";
export default {
    components:{
        ServiceItem,
    },
    data(){
        return {
            list:[],
            zqdwList:[],
            userInfoList:[],
        }
    },
    mounted(){
        this.getData();
    },
    methods:{
        ...mapActions([
            'getServiceAdministration',
            'GetZqdwList',
            'getUserInfoList'
        ]),
        async getZqdwList() {
            this.zqdwList = [];
            let result = await this.GetZqdwList();
            if (result && result.length > 0) {
                this.zqdwList = result;
            }
        },
        async getUserList() {
            this.userInfoList = [];
            let res = await this.getUserInfoList();
            console.log('===============>',res);
            
            if (res && res.length > 0) {
                this.userInfoList = res;
            }
        },
        handleClick(tab){
            console.log('tab-->',tab);
        },
        async getData(){
            const res = await this.getServiceAdministration();
            console.log('getServiceAdministration++>>',res);
            if(Array.isArray(res)){
                this.list = res;
            }
            else {
                this.list = [];
            }
            await this.getZqdwList();
            await this.getUserList();
        },
    },
}
</script>

<style lang="scss" scoped>
.service-manage{
    padding-top:15px;
    box-sizing: border-box;
}
</style>